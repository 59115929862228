.playing-card-grid {
  margin: 0px;
}

.playing-card {
  display: inline-block;
  background: #FAFAFA;
  color: black;
  border: 1px solid black;
  border-radius: 10%;
}

.playing-card.red {
  color: red;
}

.playing-card.size-normal {
  text-align: center;
  width: 60px;
  font-size: 1.6em;
  padding: 28px 0px;
  border-radius: 10px;
  cursor: default;
}

.playing-card-grid-item {
  margin-bottom: 8px !important;
}

.playing-card-card {
  padding: 4px;
  height: 100%;
}

.playing-card-grid .card-rule {
  display: inline-block;
  width: calc(100% - 60px - 2px - 4px); /* 80px for .playing-card, 2px for its border, 4px for .card-rule's margin-left */
  vertical-align: top;
  margin-left: 4px;
}

.playing-card-grid .card-rule h2 {
  margin: 0px;
  font-size: 1.2em;
}

.playing-card-grid .card-rule p {
  margin: 2px 0px;
  font-size: 0.94em;
}